/**
 * Portable Text Helper Component
 * This component is responsible for parsing the portable text data from Sanity.
 * It is often used in content managed pages.
 */

import React from "react"
import BlockContent from "@sanity/block-content-to-react"

import Figure from "../figure"
import Blockquote from "../blockquote"
import { Profiles } from "../profiles"

/**
 * Serializers
 * The serializers are responsible for handling the look and feel of the
 * appropriate type that we receive from sanity.
 */

const serializers = {
  list: (props) => {
    const { children } = props
    return <ul className="mb-6 list-disc pl-12 leading-loose">{children}</ul>
  },
  types: {
    block(props) {
      const HeadingTag = props.node.style

      switch (props.node.style) {
        case "h1":
          return (
            <HeadingTag className="text-2xl lg:text-4xl font-medium mb-8">
              {props.children}
            </HeadingTag>
          )

        case "h2":
        case "h3":
          return (
            <HeadingTag className="text-2xl lg:text-3xl font-medium mb-8">
              {props.children}
            </HeadingTag>
          )

        case "h4":
        case "h5":
        case "h6":
          return (
            <HeadingTag className="text-2xl font-medium mb-4">
              {props.children}
            </HeadingTag>
          )

        case "blockquote":
          return <Blockquote>{props.children}</Blockquote>

        // By default the assumption is to render a <p> element
        default:
          return <p className="mb-6 leading-loose">{props.children}</p>
      }
    },

    figureImage(props) {
      return props.node.asset ? (
        <Figure caption={props.node.caption} src={props.node.asset._ref} />
      ) : null
    },

    quickLink(props) {
      return <div id={props.node.ref} />
    },

    profiles_grid(props) {
      return props.node.profiles.length > 0 ? (
        <Profiles data={props.node.profiles} />
      ) : null
    },

    inlineFile(props) {
      const { node } = props

      const Icon = ({ isDownload = false }) => {
        if (isDownload) {
          return (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4"
              />
            </svg>
          )
        }

        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14"
            />
          </svg>
        )
      }

      return (
        <>
          <h2 className="text-2xl my-4 w-full">Additional Downloads</h2>
          <div className="flex flex-wrap gap-4 border-t-4 border-gray-200 py-6">
            {node.files?.length > 0 &&
              node.files.map((f) => {
                return (
                  <div
                    className="flex flex-col items-center text-center"
                    style={{ maxWidth: 180 }}
                  >
                    {f.isFileUpload ? (
                      <a
                        href={`${f.file.asset.url}?dl=`}
                        className="flex items-center flex-col"
                      >
                        <Icon isDownload />
                        <p className="text-base mt-2 block">
                          Click here to download {f.filename}
                        </p>
                      </a>
                    ) : (
                      <a
                        target="_blank"
                        href={f.linkToFile}
                        className="flex items-center flex-col"
                      >
                        <Icon />
                        <p className="text-base mt-2 block">
                          Click here to view {f.filename}
                        </p>
                      </a>
                    )}
                  </div>
                )
              })}
          </div>
        </>
      )
    },
  },
}

export default function PortableText({ blocks }) {
  return <BlockContent blocks={blocks} serializers={serializers} />
}
