import React from "react"

function Blockquote({ children }) {
  return (
    <blockquote className="serif italic text-lg lg:text-xl text-indigo-900 max-w-xl mx-auto px-4 mb-8">
      {children}
    </blockquote>
  )
}

export default Blockquote
