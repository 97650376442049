/**
 * Profiles Grid
 */

import React from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import { getGatsbyImageData } from "gatsby-source-sanity"
import { sanityConfig } from "../../utils/sanity"

export function Profiles({ data }) {
  return (
    <div className="mb-0">
      {data.map((profile) => {
        const profileImage = profile.profile_image
          ? getGatsbyImageData(
              profile.profile_image.asset._id,
              { maxWidth: 500 },
              sanityConfig
            )
          : null

        return (
          <div className="grid grid-cols-6 gap-8 pt-6 pb-2">
            <div className="col-span-2">
              <GatsbyImage className="mb-6 rounded" image={profileImage} />
            </div>

            <div className="col-span-4">
              <h2 className="text-xl serif mb-3">{profile.name}</h2>
              <p>{profile.bio}</p>
            </div>
          </div>
        )
      })}
    </div>
  )
}
